import './nunjucks-templates';
import * as nunjucks from 'nunjucks';
import { identity } from './user';
function makeRandomId() {
    const charset = '0123456789abcdef';
    const len = 16;
    let buf = '';
    for (let i = 0; i < len; i++) {
        buf += charset.charAt(Math.floor(Math.random() * charset.length));
    }
    return buf;
}
let nje = null;
async function nj() {
    if (!nje) {
        nje = new nunjucks.Environment();
    }
    // GLOBAL VARIABLES
    nje.addGlobal('fas', 'fas');
    nje.addGlobal('identity', await identity);
    nje.addGlobal('iftypes', {
        ethernet: {
            label: 'Wired Ethernet',
            icon: 'ethernet',
        },
        '802.11': {
            label: 'Wi-Fi',
            icon: 'wifi',
        },
        mta: {
            label: 'MTA',
            icon: 'router',
        },
        fiber: {
            label: 'Fiber',
            icon: 'bolt',
        },
    });
    nje.addGlobal('hosttypes', {
        'Desktop': {
            icon: 'desktop',
        },
        'Laptop': {
            icon: 'laptop',
        },
        'Server': {
            icon: 'server',
        },
        'VM': {
            icon: 'box',
        },
        'Router/Switch': {
            icon: 'router',
        },
        'Tablet/Handheld': {
            icon: 'mobile',
        },
        'Game Console': {
            icon: 'gamepad-alt',
        },
        'Other': {
            icon: 'computer-classic',
        },
    });
    nje.addGlobal('oses', {
        'Arch Linux': {
            icon: 'linux',
        },
        'Debian': {
            icon: 'linux',
        },
        'Ubuntu': {
            icon: 'ubuntu',
        },
        'Fedora': {
            icon: 'fedora',
        },
        'Red Hat': {
            icon: 'redhat',
        },
        'CentOS': {
            icon: 'centos'
        },
        'Windows': {
            icon: 'windows',
        },
        'macOS': {
            icon: 'apple',
        },
        'Apple iOS': {
            icon: 'apple',
        },
        'Android': {
            icon: 'android',
        },
        'BSD': {
            icon: 'bsd',
        },
        'Cisco IOS': {
            icon: 'router',
        },
        'Other': {
            icon: 'question fas',
        },
    });
    // GLOBAL FUNCTIONS
    nje.addGlobal('rid', function () {
        if (!this.ctx._rid) {
            this.ctx._rid = makeRandomId();
        }
        return this.ctx._rid;
    });
    nje.addGlobal('json_encode', function (v) {
        return JSON.stringify(v, null, 2);
    });
    return nje;
}
export { nj, };
