import Swal from 'sweetalert2';
import { add_route, derive_from, navigate_to } from './singlepage';
import { api } from './api';
import { alert_success } from './alert-banners';
import { action_button, load_template, load_page } from './ui';
$(window).bind('singlepagesetup', function () {
    add_route('users', function (matches) {
        let page = load_page('users'), users = api('GET', 'users');
        Promise.all([page, users]).then(values => {
            let html = values[0], users = values[1];
            $('.table-users').DataTable({
                data: users,
                columns: [
                    {
                        data: 'principal',
                        render: (cell, context, row) => {
                            return `<span>${row.principal}</span><br />` +
                                `<small class="text-muted">via <tt>${row.authorizer}</tt></small>`;
                        },
                    },
                    {
                        orderable: false,
                        className: 'actions',
                        width: '0',
                        render: (cell, context, row) => {
                            let html = '';
                            html += action_button('Edit User', 'pencil-alt', 'warning', 'btn-edit-user', 'user/' + row.id);
                            html += action_button('Delete User', 'trash', 'danger', 'btn-delete-user', `user/${row.id}/delete`);
                            return html;
                        },
                    }
                ]
            });
        });
    });
    add_route('user/create', async function (args) {
        const container = await load_template('user/edit', { 'flags': [] });
        container.find('form[name="user"]').on('success', (event, result) => {
            alert_success(`Successfully created the user "${result.name}"`);
            navigate_to(`user/${result.id}`);
        });
    });
    add_route('user/:id(guid)', async function (args) {
        const user = await api('GET', `user/${args.id}`);
        const container = await load_template('user/edit', user);
        container.find('form[name="user"]').on('success', (event, result) => {
            alert_success(`Updated user "${result.principal}"`);
        });
    });
    add_route('user/:id(guid)/delete', async function (args) {
        await derive_from('users');
        const user = await api('GET', `user/${args.id}`);
        const hostCountStr = user.host_count == 0 ?
            `This will ` :
            user.host_count == 1 ?
                `This will delete the user's 1 registered host and ` :
                `This will delete the user's ${user.host_count} registered hosts and `;
        const answer = await Swal.fire({
            title: `Delete user "${user.principal}"?`,
            text: hostCountStr +
                `remove any custom permissions. They can be recreated if they log in using ` +
                `an external authorization provider. To prevent this user from logging in ` +
                `to Machines, edit the user and disable interactive login.`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Delete User',
        });
        if (answer.isConfirmed) {
            await api('DELETE', `user/${user.id}`);
            alert_success(`Deleted user "${user.principal}".`);
            $('.page-content table.table-users').DataTable()
                .rows((_, row) => row.id == user.id)
                .remove()
                .draw();
        }
        navigate_to('users');
    });
});
