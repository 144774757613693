import Swal from 'sweetalert2';
import { api } from './api';
const NULL_IDENTITY = {
    identity: null,
    source: null,
    abilities: [],
    flags: [],
    has_ability: () => false,
};
let identity;
function init_identity() {
    identity = new Promise((resolve, reject) => {
        $(async function () {
            const identity = await api('GET', 'auth/identity');
            const account_container = $('#nav-account-trigger').parents('li:first');
            if (identity) {
                identity.has_ability = (ability) => {
                    return identity.abilities.indexOf(ability) > -1 ||
                        identity.abilities.indexOf('superadmin') > -1;
                };
                $('#nav-account-trigger').attr('href', null);
                account_container.find('.lbl-username').text(identity.identity || '');
                account_container.find('.lbl-authorized-by').text(identity.source || '');
                account_container.find('.lbl-abilities').text(identity.abilities.join(', '));
                if (identity.flags.indexOf('noninteractive') > -1) {
                    await Swal.fire({
                        icon: 'warning',
                        title: 'Account disabled',
                        text: 'Your account does not allow interactive login.',
                    });
                    return redirect_to_login();
                }
                return resolve(identity);
            }
            $('#nav-account-trigger').attr('data-toggle', null).removeClass('dropdown-toggle')
                .on('click', function (event) {
                // When the "Log in" button is clicked, intercept hyperlink
                // and call redirect_to_login() instead so the user is
                // brought back to the current page.
                event.preventDefault();
                event.stopPropagation();
                redirect_to_login();
                return false;
            });
            return resolve(NULL_IDENTITY);
        });
    });
}
init_identity();
async function force_login() {
    await Swal.fire({
        icon: 'warning',
        title: 'Login required',
        text: 'Guest access isn\'t enabled. You will need to log in to continue.',
    });
    redirect_to_login();
}
function redirect_to_login() {
    const return_to = window.location.pathname + window.location.search;
    window.location.href = '/login' + return_to;
}
export { force_login, identity, init_identity, redirect_to_login, };
