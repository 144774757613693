import Swal from 'sweetalert2';
import { add_route, derive_from, navigate_to } from './singlepage';
import { api } from './api';
import { alert_success } from './alert-banners';
import { action_button, load_page, load_template } from './ui';
$(window).bind('singlepagesetup', function () {
    add_route('oauth', async function (args) {
        const [page, clients] = await Promise.all([
            load_page('oauth'),
            api('GET', 'oauth'),
        ]);
        $('.table-oauth').DataTable({
            data: clients,
            columns: [
                {
                    data: 'id',
                    className: 'xm-col-guid',
                },
                {
                    data: 'friendly_name',
                },
                {
                    orderable: false,
                    className: 'actions',
                    width: '0',
                    render: (cell, context, row) => {
                        let html = '';
                        html += action_button('Edit Client', 'pencil-alt', 'warning', 'btn-edit-client', `oauth/${row.id}`);
                        html += action_button('Delete Client', 'trash', 'danger', 'btn-delete-site', `oauth/${row.id}/delete`);
                        return html;
                    },
                }
            ]
        });
    });
    add_route('oauth/:client(guid)/delete', async function (args) {
        await derive_from('oauth');
        const client = await api('GET', `oauth/${args.client}`);
        const answer = await Swal.fire({
            icon: 'warning',
            title: `Delete the OAuth client "${client.friendly_name}"?`,
            text: 'This will revoke the client credentials immediately.',
            showCancelButton: true,
            confirmButtonText: 'Confirm',
        });
        if (answer.isConfirmed) {
            await api('DELETE', `oauth/${client.id}`);
            alert_success(`Deleted client client "${client.friendly_name}"`);
            $('.page-content table.table-oauth').DataTable()
                .rows((_, row) => row.id == client.id)
                .remove()
                .draw();
        }
        navigate_to('oauth');
    });
    add_route('oauth/create', async function (args) {
        const container = await load_template('oauth/edit', {});
        container.find('form[name="client"]').on('success', (event, result) => {
            Swal.fire({
                icon: 'success',
                title: 'Client Created',
                html: `
                            <p>Your client ID and client secret are shown below.
                            <strong>Write them down!</strong> The secret won't
                            be shown again.</p>

                            <p>Client ID: <tt>${result.client_id}</tt></p>
                            <p>Secret: <tt>${result.client_secret}</tt></p>
                            `,
            });
            navigate_to('oauth');
        });
    });
    add_route('oauth/:client(guid)', async function (args) {
        const client = await api('GET', `oauth/${args.client}`);
        const container = await load_template('oauth/edit', client);
        container.find('form[name="client"]').on('success', (event, result) => {
            alert_success(`Updated client "${result.friendly_name}".`);
        });
    });
});
