import Swal from 'sweetalert2';
import 'bootstrap';
import { add_route, derive_from, navigate_to, parse_query } from './singlepage';
import { api } from './api';
import { bind_mqtt_event } from './events';
import { action_button, load_template } from './ui';
import { alert_success, alert_error } from './alert-banners';
import { nj } from './nunjucks-environment';
$(window).bind('singlepagesetup', function () {
    add_route('host/:id(guid)/mdm', async function (args) {
        let [host, mdm, commands] = await Promise.all([
            api('GET', 'host/' + args.id),
            api('GET', `host/${args.id}/mdm/status`),
            api('GET', `host/${args.id}/mdm/commands`),
        ]);
        host.mdm = mdm;
        host.mdm.ios_regexp = /^(iPhone|iPad|iPod)/;
        const container = await load_template('host/mdm/commands', host);
        const table = container.find('.table-mdm-commands').DataTable({
            data: commands,
            pageLength: 50,
            order: [[2, 'desc']],
            columns: [
                {
                    data: 'args.RequestType',
                },
                {
                    data: 'state',
                    render: (cell, context, row) => {
                        let labelClass = 'badge-secondary', labelText = cell;
                        switch (cell) {
                            case 'done':
                                labelClass = 'badge-success';
                                labelText = 'Finished';
                                break;
                            case 'init':
                                labelText = 'Unacknowledged';
                                break;
                            case 'notnow':
                                labelClass = 'badge-warning';
                                labelText = 'On Hold';
                                break;
                            case 'error':
                                labelClass = 'badge-danger';
                                labelText = 'Failed';
                                break;
                        }
                        return `<span class="badge ${labelClass}">${labelText}</span>`;
                    },
                },
                {
                    data: (row) => row.updated_at || row.created_at,
                    // sort: (row) => row.updated_at || row.created_at,
                    render: (cell, context, row) => {
                        if (context != 'display') {
                            return cell;
                        }
                        let $elem = $(`<span class="timeago" data-timestamp="${cell}">&hellip;</span>`);
                        $elem.prettyTimestamp();
                        return $elem.html();
                    },
                },
                {
                    orderable: false,
                    className: 'actions',
                    width: '0',
                    render: (cell, context, row) => {
                        let html = '';
                        if (row.state == 'notnow' || row.state == 'init') {
                            html += action_button('Nudge', 'concierge-bell', 'info', 'btn-nudge-command', `host/${host.id}/mdm/command/${row.id}/nudge`);
                            html += action_button('Cancel', 'times', 'danger', 'btn-cancel-command', `host/${host.id}/mdm/command/${row.id}/cancel`);
                        }
                        else if (row.state == 'done' || row.state == 'error') {
                            html += action_button('View Result', 'search', 'primary', 'btn-view-command', `host/${host.id}/mdm/command/${row.id}`);
                        }
                        return html;
                    },
                },
            ],
        });
        bind_mqtt_event({ "thing": "mdm_command", "tags": { "host": host.id } }, async (mqev) => {
            const id = mqev.tags ? mqev.tags.mdm_command : undefined;
            switch (mqev.event) {
                case 'created':
                    // command details aren't provided in mqtt events to prevent data disclosure
                    const command = await api('GET', `host/${host.id}/mdm/command/${id}`);
                    table.row.add(command);
                    table.draw();
                    break;
                case 'deleted':
                    table.rows((idx, data, node) => data.id == id).remove();
                    table.draw();
                    break;
                case 'updated':
                    let rows = table.rows((idx, data, node) => data.id == id);
                    if (rows.length > 0) {
                        if (mqev.tags && mqev.tags.state) {
                            // this is an optimization: if the tags show a state change, we just process that
                            // and don't refetch the whole event
                            for (let i = 0; i < rows.length; i++) {
                                let d = table.row(rows[i]).data();
                                d.state = mqev.tags.state;
                                table.row(rows[i]).data(d);
                            }
                        }
                        else {
                            // fetch the whole command
                            const command = await api('GET', `host/${host.id}/mdm/command/${id}`);
                            for (let i = 0; i < rows.length; i++) {
                                table.row(rows[i]).data(command);
                            }
                        }
                        table.draw();
                    }
                    break;
            }
        });
    });
    add_route('host/:id(guid)/mdm/command', async function (args) {
        await derive_from(`host/${args.id}/mdm`);
        let query = parse_query();
        let cmd = query['a'] || '';
        let $btn = $('.btn-mdm-run-command');
        let payload = null;
        let answer;
        $btn.button('toggle').dropdown('hide');
        switch (cmd) {
            default:
                if (cmd == '') {
                    alert_error("No command specified");
                }
                else {
                    alert_error(`Unknown command: ${cmd}`);
                }
                break;
            case 'repush':
                let profile = query['p'] || 'mainline';
                let scope = (profile === 'user' ?
                    { 'PayloadScope': 'User', 'xx0r:Scope:AllUsers': true } :
                    { 'PayloadScope': 'System' });
                payload = {
                    'RequestType': 'InstallProfile',
                    'xx0r:ProfileType': profile,
                    // ...scope,
                };
                alert_success("The current MDM profile will be pushed to the host.");
                break;
            case 'lock':
                answer = await Swal.fire({
                    title: 'Enter a PIN to lock the system',
                    text: 'This will immediately force-reboot the device!',
                    input: 'text',
                    inputPlaceholder: '6 digits, 0-9',
                    confirmButtonText: 'Lock System',
                    showCancelButton: true,
                    inputValidator: value => {
                        if (!/^[0-9]{6}$/.test(value)) {
                            return 'Please enter a 0-6 digit PIN.';
                        }
                        return false;
                    },
                });
                if (answer.isConfirmed) {
                    payload = {
                        'RequestType': 'DeviceLock',
                        'PIN': answer.value,
                    };
                    alert_success("Device will be locked.");
                }
                break;
            case 'shutdown':
                answer = await Swal.fire({
                    title: 'Confirm Shutdown',
                    text: 'This will immediately force-power-off the device!',
                    confirmButtonText: 'Shut Down',
                    showCancelButton: true,
                });
                if (answer.isConfirmed) {
                    payload = {
                        'RequestType': 'ShutDownDevice',
                    };
                    alert_success("Device will be shut down momentarily.");
                }
                break;
            case 'list-profiles':
                payload = {
                    'RequestType': 'ProfileList',
                };
                alert_success("The device will momentarily report a list of installed profiles. Stand by.");
                break;
            case 'list-users':
                payload = {
                    'RequestType': 'UserList',
                };
                alert_success("Asking the device for a list of users. Stand by.");
                break;
            case 'list-certificates':
                payload = {
                    'RequestType': 'CertificateList',
                };
                alert_success("Asking the device for a list of certificates. Stand by.");
                break;
        }
        if (payload) {
            await api('POST', `host/${args.id}/mdm/command`, { payload });
        }
        $btn.button('dispose');
        return navigate_to(`host/${args.id}/mdm`);
    });
    add_route('host/:host(guid)/mdm/command/:id(guid)/cancel', async function (args) {
        await derive_from(`host/${args.host}/mdm`);
        try {
            await api('DELETE', `host/${args.host}/mdm/command/${args.id}`);
            alert_success("Command was cancelled.");
        }
        catch (e) {
            alert_error("Failed to cancel command.");
        }
        return navigate_to(`host/${args.host}/mdm`);
    });
    add_route('host/:host(guid)/mdm/command/:id(guid)/nudge', async function (args) {
        await derive_from(`host/${args.host}/mdm`);
        try {
            await api('PUT', `host/${args.host}/mdm/command/${args.id}`);
            alert_success("Command will be re-pushed to the host.");
        }
        catch (e) {
            alert_error("Failed to nudge command.");
        }
        return navigate_to(`host/${args.host}/mdm`);
    });
    add_route('host/:host(guid)/mdm/command/:id(guid)', async function (args) {
        await derive_from(`host/${args.host}/mdm`);
        const cmd = await api('GET', `host/${args.host}/mdm/command/${args.id}`);
        const $modal = $('#modal-command-details');
        $modal.find('.modal-body').html((await nj()).render('host/mdm/command-result', { cmd }));
        try {
            $modal.find('.timestamp').prettyTimestamp();
        }
        catch (e) { }
        $modal.modal('show');
        $modal.unbind('hide.bs.modal').on('hide.bs.modal', () => {
            navigate_to(`host/${args.host}/mdm`);
        });
    });
});
