function alert_success(text) {
    return alert_banner('success', text);
}
function alert_error(text) {
    return alert_banner('danger', text);
}
function alert_banner(subclass, text) {
    $('body > .float-banner').each((i, e) => {
        let outerHeight = e ? $(e).outerHeight() || 0 : 0;
        let newTop = parseInt($(e).css('top')) + outerHeight + 20;
        $(e).animate({
            top: `${newTop}px`,
        });
    });
    const banner = $('<div />').addClass(`alert alert-${subclass} float-banner`).text(text);
    banner.css('position', 'fixed')
        .css('right', '20px')
        .css('top', '0')
        .css('opacity', '0.0')
        .appendTo('body');
    banner.animate({ top: '20px', opacity: '1.0' });
    let timeout = setTimeout(() => banner.fadeOut(() => banner.remove()), 5000);
    banner.on('click', () => { banner.remove(); clearTimeout(timeout); });
}
export { alert_banner, alert_error, alert_success, };
