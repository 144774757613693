import { alert_error } from './alert-banners';
import { api } from './api';
import { nj } from './nunjucks-environment';
const validTagName = /^[a-z]([a-z0-9_]*[a-z0-9])?$/;
const literalFormat = /^([0-9]+(\.[0-9]+)?|true|false|null)$/;
$(() => {
    $('.page-content').on('content-load', function () {
        $(this).find('.tag-container').each((i, e) => {
            const thing = $(e).data('thing'), thing_id = $(e).data('id');
            if (thing && thing_id) {
                setup_tag_editor($(e), thing, thing_id);
            }
        });
        $('form', this).on('beforesubmit', function (event) {
            if (!event.formData) {
                return;
            }
            if (event.formData.new_tags_names && event.formData.new_tags_values) {
                delete event.formData.new_tags_names;
                delete event.formData.new_tags_values;
            }
            if (event.formData.tags) {
                delete event.formData.tags;
            }
            $('.tag-container', this).trigger('machines.xx0r.save-tags');
        });
        $('.tag-container', this).on('machines.xx0r.save-tags', event => {
            save_tags($(event.currentTarget));
        });
    });
});
async function setup_tag_editor($target, thing, thing_id) {
    let tags = await api('GET', `tags/${thing}/${thing_id}`);
    $target.empty().html((await nj()).render('tag-editor', { tags, thing, thing_id }));
    $target.on('click', '.btn-insert-tag', function () {
        let $drop = $(this).parents('.ctr-add-tag:first');
        (async function () {
            $drop.before((await nj()).render('bits/tag-entry', { tag: { name: "", value: "" } }));
        })();
        return false;
    });
}
async function save_tags($tc) {
    let tagInputs = $tc.find('input:text[name^="tags["]'), newNames = $tc.find('input:text[name="new_tags_names[]"]'), newValues = $tc.find('input:text[name="new_tags_values[]"]'), thingType = $tc.data('thing'), thingId = $tc.data('id'), tags = {};
    tagInputs.each((i, e) => {
        let v = $(e).val() || null;
        if (typeof (v) === 'string' && literalFormat.test(v)) {
            v = JSON.parse(v);
        }
        tags[$(e).data('tag-name')] = v;
    });
    newNames.each((i, newName) => {
        let tag_name = $(newName).val(), el = newValues.get(i), tag_value = el instanceof HTMLElement ? $(el).val() : '';
        if (!validTagName.test(tag_name)) {
            alert_error(`Tag not saved due to invalid tag name: "${tag_name}"`);
            return;
        }
        if (tags[tag_name]) {
            alert_error(`Conflicting tag name: "${tag_name}"`);
            return;
        }
        if (tag_value.length < 1) {
            alert_error(`Empty tag value for tag: "${tag_name}"`);
            return;
        }
        if (literalFormat.test(tag_value)) {
            tag_value = JSON.parse(tag_value);
        }
        tags[tag_name] = tag_value;
    });
    let response = await api('PUT', `tags/${thingType}/${thingId}`, tags);
    $tc.html((await nj()).render('tag-editor', { 'tags': response, 'thing': thingType, 'thing_id': thingId }));
}
