import Swal from 'sweetalert2';
let baseUrl = `${window.location.protocol}//${window.location.host}/api`;
class ApiOptions {
    handleError(e) {
        console.debug(e);
        if (typeof (e) === 'object' && e.message) {
            Swal.fire({
                icon: 'error',
                title: 'Unable to complete action',
                text: e.message,
            });
        }
        else {
            Swal.fire({
                icon: 'error',
                title: 'API call failed',
                text: 'Something went wrong during an API call to the ' +
                    'server. We\'ve made a note of this error.'
            });
        }
    }
}
class ApiOptionsSilentError extends ApiOptions {
    handleError(e) { }
}
async function api(method, uri, body, opts) {
    opts = opts || new ApiOptions();
    if (uri.charAt(0) != '/') {
        uri = '/' + uri;
    }
    let fetchOpts = { method };
    if (['PUT', 'POST', 'PATCH'].indexOf(method) > -1) {
        fetchOpts.body = JSON.stringify(body);
        fetchOpts.headers = {
            'Content-Type': 'application/json'
        };
    }
    let req = new Request(baseUrl + uri, fetchOpts);
    try {
        let resp = await fetch(req), json = resp.json();
        if (!resp.ok) {
            throw (json);
        }
        return json;
    }
    catch (e) {
        opts.handleError(e);
        throw (e);
    }
}
;
export { baseUrl, ApiOptions, ApiOptionsSilentError, api, };
